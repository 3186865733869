import React, { useState, useEffect } from "react";

interface PDFPickerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectPDF: (pdfUrl: string) => void;
}

const PDFPickerModal: React.FC<PDFPickerModalProps> = ({
  isOpen,
  onClose,
  onSelectPDF,
}) => {
  const [pdfs, setPDFs] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileExists, setFileExists] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      fetch(`${process.env.REACT_APP_API_URL}/api/manage/`)
        .then((response) => response.json())
        .then((data) => setPDFs(data.pdfs || []))
        .catch((error) => {
          console.error("Error fetching PDFs:", error);
          setPDFs([]); // Set an empty array if fetching fails
        });
    }
  }, [isOpen]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      setFileExists(false); // Reset the file exists state when a new file is selected
    }
  };

  const handleUpload = async () => {
    if (!file) return;
  
    setIsLoading(true);
    setErrorMessage(null);
  
    try {
      const formData = new FormData();
      formData.append("file", file);
  
      const uploadResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/manage/create`, {
        method: "POST",
        body: formData,
      });
  
      if (uploadResponse.status === 409) {
        // File already exists
        setFileExists(true);
        setIsLoading(false);
        return;
      }
  
      if (!uploadResponse.ok) throw new Error("Failed to upload PDF");
  
      const { pdfUrl } = await uploadResponse.json(); // Get the URL from backend response
  
      // Pass the new pdfUrl back to the parent
      onSelectPDF(pdfUrl); 
  
      // Clear state and close the modal after successful upload
      setFile(null);
      setIsLoading(false);
      onClose(); // Automatically close the modal after upload
    } catch (error) {
      console.error("Error uploading PDF:", error);
      setErrorMessage("PDF upload failed");
      setIsLoading(false);
    }
  };
  

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Select or Upload PDF</h2>

            <input type="file" accept=".pdf" onChange={handleFileChange} className="mb-4" />
            <button
              onClick={handleUpload}
              className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
              disabled={isLoading}
            >
              {isLoading ? "Uploading..." : "Upload"}
            </button>

            {fileExists && (
              <p className="text-red-500">A PDF with this name already exists. Please choose another file.</p>
            )}

            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            <div>
              <h3 className="text-xl font-bold mb-2">Existing PDFs</h3>
              {pdfs && pdfs.length > 0 ? (
                <div className="grid grid-cols-3 gap-4">
                  {pdfs.map((pdfUrl) => (
                    <div key={pdfUrl}>
                      <a
                        href={pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        View PDF
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No PDFs found.</p>
              )}
            </div>

            <button onClick={onClose} className="mt-4 bg-red-500 text-white px-4 py-2 rounded">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFPickerModal;
