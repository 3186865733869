import React, { useState, useEffect } from "react";
import { DndContext, closestCenter, DragOverlay } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import SortablePDFItem from "../components/SortablePDFItem";
import PDFPickerModal from "../components/PDFPickerModal";
import AdminNavBar from '../components/AdminNavBar';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../components/Layout";

// Define the structure for each COA entry
interface CoaEntry {
  id: number;
  title: string;
  description: string;
  pdfUrl: string;
}

export default function ManageCoas() {
  const [coas, setCoas] = useState<CoaEntry[]>([]);
  const [isPDFUploaderOpen, setIsPDFUploaderOpen] = useState(false);
  const [activeCoa, setActiveCoa] = useState<CoaEntry | null>(null);

  useEffect(() => {
    fetchCoas();
  }, []);

  const fetchCoas = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/manage`);
      const data: CoaEntry[] = await response.json();
      setCoas(data);
    } catch (error) {
      console.error("Failed to fetch COAs:", error);
    }
  };

  const handleSaveCoa = async (updatedCoa: CoaEntry) => {
    const endpoint = updatedCoa.id
      ? `${process.env.REACT_APP_API_URL}/api/manage/update/${updatedCoa.id}`
      : `${process.env.REACT_APP_API_URL}/api/manage/create`;

    try {
      const response = await fetch(endpoint, {
        method: updatedCoa.id ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedCoa),
      });

      if (!response.ok) {
        throw new Error(`Failed to save COA: ${response.statusText}`);
      }

      const result = await response.json();
      toast.success("COA saved successfully!");
      console.log('Save successful', result);
      fetchCoas();
    } catch (error) {
      toast.error("Failed to save COA");
      console.error('Failed to save COA:', error);
    }
  };

  const handleDeleteCoa = async (id: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/manage/delete/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        setCoas(coas.filter(coa => coa.id !== id));
        toast.success("COA deleted successfully!");
        console.log('Delete successful');
      } else {
        throw new Error('Failed to delete COA');
      }
    } catch (error) {
      toast.error("Failed to delete COA");
      console.error('Failed to delete COA:', error);
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over || !active) return;
    if (active.id !== over.id) {
      const oldIndex = coas.findIndex(coa => coa.id === parseInt(active.id));
      const newIndex = coas.findIndex(coa => coa.id === parseInt(over.id));
      const reorderedCoas = arrayMove(coas, oldIndex, newIndex);
      setCoas(reorderedCoas);
      toast.success("COA order updated successfully!");
    }
  };

  const handleOpenPDFUploader = (coa?: CoaEntry) => {
    setActiveCoa(coa || null);
    setIsPDFUploaderOpen(true);
  };

  return (
    <Layout>
      <AdminNavBar />
      <div className="pt-[110px] min-h-screen bg-fractal-pattern-3 bg-cover bg-fixed p-10 relative">
        <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-10">
          Manage Certificates of Analysis (COAs)
        </h1>

        {/* Upload New COA Button */}
        <div className="text-center mb-8">
          <button
            onClick={() => {
              toast.info("Opening PDF uploader...");
              handleOpenPDFUploader();
            }}
            className="bg-gradient-to-r from-green-400 to-green-600 text-white font-bold py-3 px-8 rounded-lg shadow-md hover:from-green-500 hover:to-green-700 transition duration-300"
          >
            Upload New COA
          </button>
        </div>

        {/* Sortable List of COAs */}
        {coas.length > 0 ? (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={coas.map((coa) => coa.id)} strategy={verticalListSortingStrategy}>
              <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {coas.map((coa) => (
                  <SortablePDFItem
                    key={coa.id.toString()}
                    item={{
                      id: coa.id,
                      title: coa.title,
                      description: coa.description || "",
                      file: { pdfUrl: coa.pdfUrl },
                    }}
                    onSave={(updatedItem) => handleSaveCoa({
                      id: updatedItem.id,
                      title: updatedItem.title,
                      description: updatedItem.description || "",
                      pdfUrl: updatedItem.file?.pdfUrl || "",
                    })}
                    onDelete={() => handleDeleteCoa(coa.id)}
                    onTitleChange={(title) =>
                      setCoas(coas.map((s) =>
                        s.id === coa.id ? { ...s, title } : s))}
                    onDescriptionChange={(description) =>
                      setCoas(coas.map((s) =>
                        s.id === coa.id ? { ...s, description } : s))}
                  />
                ))}
              </div>
            </SortableContext>

            <DragOverlay>
              {activeCoa && (
                <div className="bg-white p-6 rounded-lg shadow-lg cursor-grabbing">
                  <h2 className="text-xl font-bold">{activeCoa.title}</h2>
                  <p className="text-sm text-gray-600">{activeCoa.description}</p>
                </div>
              )}
            </DragOverlay>
          </DndContext>
        ) : (
          <div className="text-center mt-10 text-gray-600 text-lg">
            <p>No COAs available to display. Please add some data.</p>
          </div>
        )}

        {/* PDF Picker Modal */}
        {isPDFUploaderOpen && (
          <PDFPickerModal
            isOpen={isPDFUploaderOpen}
            onClose={() => {
              setIsPDFUploaderOpen(false);
              toast.info("Closed PDF uploader");
            }}
            onSelectPDF={(pdfUrl) => {
              if (activeCoa) {
                const updatedCoa = { ...activeCoa, pdfUrl };
                handleSaveCoa(updatedCoa)
                  .then(() => {
                    setCoas((prevCoas) => [
                      ...prevCoas,
                      {
                        id: Date.now(),
                        title: "Untitled",
                        description: "",
                        pdfUrl,
                      },
                    ]);
                    setIsPDFUploaderOpen(false);
                    toast.success("PDF uploaded and COA saved successfully!");
                  })
                  .catch((error) => {
                    console.error("Failed to save COA:", error);
                    toast.error("Failed to save COA");
                  });
              }
            }}
          />
        )}

        {/* Toast Container for Notifications */}
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar={false} />
      </div>
    </Layout>
  );
}
