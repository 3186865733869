import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        
        {/* Left side: Company Information */}
        <div className="text-sm">
          <p>Ridgetop Botanicals &copy; 2024</p>
          <p>Rutland, VT</p>
          <p>Phone: +707 367 0033</p>
          <p>Email: <a href="mailto:ridgetopfarmvtllc@gmail.com" className="hover:text-blue-300">ridgetopfarmvtllc@gmail.com</a></p>
        </div>
        
        {/* Right side: Social Media Icons */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-500">
            <FaFacebook size={24} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400">
            <FaTwitter size={24} />
          </a>
          <a href="https://www.instagram.com/mdavis3987/" target="_blank" rel="noopener noreferrer" className="hover:text-pink-500">
            <FaInstagram size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
}
