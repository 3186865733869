import { useEffect, useState } from 'react';
import Layout from '../components/Layout'; // Import Layout
import { Quote } from '../types/types'; 
import { Parallax } from 'react-scroll-parallax';
import ImageCarousel from '../components/ImageCarousel'; // Import the ImageCarousel component

// Define the About Us section type
type AboutUsSectionType = {
  id: number;
  title: string;
  text: string;
  file: { 
    imageUrl: string;
  };
};

const Home = () => {
  const [aboutUsSections, setAboutUsSections] = useState<AboutUsSectionType[]>([]);
  const [quote, setQuote] = useState<Quote | null>(null); // State to hold the quote
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(true); // State for header transparency
  const [error, setError] = useState<string | null>(null);

  // Fetch data from the backend API
  useEffect(() => {
    console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
    // Fetch About Us Sections
    fetch(`${process.env.REACT_APP_API_URL}/api/about-us`)
    
    // Make sure this points to your backend
      .then((res) => {
        console.log("Raw Response for About Us Sections: ", res);
        if (!res.ok) {
          throw new Error('Failed to fetch About Us Sections. Please check your server.');
        }
        return res.json();
      })
      .then((data) => {
        console.log('About Us Sections fetched:', data); // Log fetched data
        setAboutUsSections(data);
      })
      .catch((error) => {
        console.error('Error fetching sections:', error);
        setError('Error fetching About Us Sections.');
      });

    // Fetch Quote
    fetch(`${process.env.REACT_APP_API_URL}/api/quotes`) // Make sure this points to your backend
      .then((res) => {
        console.log("Raw Response for Quotes: ", res);
        if (!res.ok) {
          throw new Error('Failed to fetch quotes. Please check your server.');
        }
        return res.json();
      })
      .then((data) => {
        if (data.length > 0) {
          console.log('Quotes fetched:', data); // Log fetched data
          setQuote(data[0]); // Assuming you only want to display the first quote
        } else {
          console.warn('No quotes found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching quote:', error);
        setError('Error fetching Quote.');
      });
  }, []);

  // Handle header transparency based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsHeaderTransparent(false);
      } else {
        setIsHeaderTransparent(true);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup scroll event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Transparent Header */}
      <div className={`fixed top-0 left-0 right-0 z-50 transition duration-300 ease-in-out ${isHeaderTransparent ? 'bg-transparent text-white' : 'bg-gray-900 text-white'}`}>
      </div>

      <Layout> {/* Wrap your content with Layout */}
        <div className="min-h-screen bg-black text-white flex flex-col">
          {error && <p className="text-red-500 text-center">{error}</p>} {/* Display error if any */}

          {/* Landing Section with Background Parallax */}
          <Parallax translateY={[-30, 30]}>
            <section
              className="relative flex items-center min-h-screen bg-cover bg-center"
              style={{ backgroundImage: 'url(/images/budBackground.png)' }}
            >
              <div className="text-left p-8 md:p-20 max-w-lg ml-8 md:ml-20">
                <h1 className="text-5xl font-bold text-white mb-4">Welcome to Ridgetop Botanicals</h1>
                <p className="text-2xl text-white mb-8">Where nature meets terpenes</p>
                <a
                  href="#section1"
                  className="px-8 py-4 bg-white text-black text-xl rounded hover:bg-gray-300 transition"
                >
                  Learn More Details
                </a>
              </div>
            </section>
          </Parallax>

          {/* Render About Us Sections with Parallax Effect */}
          {aboutUsSections.map((section, index) => (
            <section
              key={section.id}
              id={`section${index + 1}`}
              className={`p-16 text-black flex flex-col md:flex-row ${index % 2 === 0 ? '' : 'md:flex-row-reverse'} items-center`}
            >
              {/* Container around Parallax to maintain flex behavior */}
              <div className="md:w-1/2 md:pr-8">
                <Parallax translateY={[-20, 20]}>
                  <div 
                    className="p-8 rounded-lg shadow-lg relative"
                    style={{
                      backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(/images/${index % 3 === 0 ? 'fracGrey.png' : index % 3 === 1 ? 'fracGrey2.png' : 'fracGrey3.png'})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundBlendMode: 'overlay',
                      backgroundColor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.4)' : 'rgba(240, 240, 240, 0.4)',
                    }}
                  >
                    <h2 className="text-4xl font-bold mb-4">{section.title}</h2>
                    <p className="text-xl mb-8">{section.text}</p>
                    {index < aboutUsSections.length - 1 && (
                      <a
                        href={`#section${index + 2}`}
                        className="px-8 py-4 bg-gradient-to-r from-green-500 to-green-700 text-white font-bold rounded-lg hover:from-green-600 hover:to-green-800 transition duration-300 ease-in-out shadow-lg transform hover:-translate-y-1"
                      >
                        Learn More
                      </a>
                    )}
                  </div>
                </Parallax>
              </div>

              {/* Image Container */}
              <div className="md:w-1/2 flex items-center justify-center">
                <Parallax translateY={[10, -10]}>
                  <img
                    src={section.file.imageUrl}
                    alt={section.title}
                    className="rounded-lg shadow-lg"
                  />
                </Parallax>
              </div>
            </section>
          ))}

          {/* Render Quote Section */}
          {quote && (
            <Parallax translateY={[-15, 15]}>
              <section className="relative w-full flex items-center justify-center p-16 bg-black text-white">
                <div className="relative w-full md:w-1/2">
                  <img
                    src={quote.imageUrl}
                    alt="Quote"
                    className="w-full h-auto rounded-lg shadow-lg"
                  />
                  <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center p-4 md:p-8"
                      style={{ color: quote.textColor }}
                  >
                    <blockquote className="mb-4 font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                      &ldquo;{quote.text}&rdquo;
                    </blockquote>
                    <footer className="font-semibold text-sm md:text-lg lg:text-xl xl:text-2xl">
                      - {quote.author || 'Anonymous'}
                    </footer>
                  </div>
                </div>
              </section>
            </Parallax>
          )}


          {/* Carousel at the bottom of the page */}
          <ImageCarousel />
        </div>
      </Layout>
    </div>
  );
};

export default Home;
