import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { Parallax } from 'react-scroll-parallax';

// Define the PDF type
type PDF = {
  id: number;
  title: string;
  description: string;
  pdfUrl: string;
};

const TestResults = () => {
  const [pdfs, setPdfs] = useState<PDF[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPdfs, setFilteredPdfs] = useState<PDF[]>([]);

  useEffect(() => {
    const fetchPDFs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/manage/`);
        const data = await response.json();
        setPdfs(Array.isArray(data) ? data : []);
        setFilteredPdfs(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching PDFs:", error);
      }
    };

    fetchPDFs();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setFilteredPdfs(pdfs.filter(
      (pdf) =>
        pdf.title.toLowerCase().includes(value.toLowerCase()) ||
        pdf.description.toLowerCase().includes(value.toLowerCase())
    ));
  };

  return (
    <Layout>
      <div className="bg-fractal-pattern min-h-screen bg-cover bg-fixed p-6 pt-48"> {/* Increased padding-top for more spacing */}
        <Parallax translateY={[-20, 20]} className="container mx-auto p-8 bg-white bg-opacity-80 rounded-lg shadow-lg bg-weed-leaf bg-cover bg-center min-h-full"> {/* Added parallax effect */}
          <h1 className="text-3xl font-bold text-center mb-8">C.O.A.</h1> 

          {/* Search Input */}
          <div className="mb-8"> 
            <input
              type="text"
              placeholder="Search by title or description..."
              className="border border-gray-300 p-3 rounded w-full" 
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          {filteredPdfs.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"> 
              {filteredPdfs.map((pdf) => (
                <Parallax key={pdf.id} translateY={[10, -10]} className="bg-white p-6 rounded-lg shadow-lg"> 
                  <h2 className="font-semibold text-xl mb-4">{pdf.title}</h2>
                  <p className="text-gray-600 mb-6">{pdf.description}</p> 

                  {/* Display PDF using an iframe */}
                  <iframe
                    src={pdf.pdfUrl}
                    width="100%"
                    height="500px"
                    style={{ border: "1px solid #ccc" }}
                    title={pdf.title}
                  ></iframe>
                </Parallax>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500">No test results available.</p>
          )}
        </Parallax>
      </div>
    </Layout>
  );
};

export default TestResults;
